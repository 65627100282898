import React, { FunctionComponent } from "react";
import { getIpglnDescription } from '../utils/utils.ts'
import Step1 from './step1'
import Step2 from './step2'
//import Step3 from './step3_old'
import Step3 from './step3'
import Step4 from "./step4";
import Step5 from './step5'

enum Steps {
    Start = 'Step1',
    Step1 = 'Step1,Step2',
    Step2 = 'Step1,Step2,Step3,Step4',
    Step3 = 'Step1,Step2,Step3,Step4',
    Step4 = 'Step1,Step3,Step4,Step5',
    Step5 = 'Start'
}

const DashedLine: FunctionComponent<{}> = () => <div className={"border_div"}>
    <div className={"dashed_div"}>

    </div>
</div>

type newProps = {

}
type newState = {
    fileName: string,
    nextStep: string,
    market: string,
    language: string,
    ipgln: string,
    assortmentCode: "",
    env: string, 
    gcs_key:string,
    bucket_key: string,
    showCreds:boolean,
    assortmentCodeWithBrandName: string,
    ipglnCodeWithDescription: string,
    disablePasDownload: boolean,
    disableAugmentButton: boolean,
    nonModifiableGtins: []
}

class Body extends React.Component<newProps, newState>{
    constructor(props) {
        super(props)
        this.state = {
            fileName: "",
            nextStep: Steps.Start,
            market: "",
            language: "",
            ipgln: "",
            assortmentCode: "",
            env: "",
            gcs_key:"",
            bucket_key: "",
            showCreds: false,
            assortmentCodeWithBrandName:"",
            ipglnCodeWithDescription:"",
            disablePasDownload: true,
            disableAugmentButton: true,
            nonModifiableGtins: []
        }
    }

    setNonModifiableGtins = (gtins) => {
        this.setState({
            nonModifiableGtins: gtins
        })
    }

    moveNextStep = (step) => {
        this.setState({
            nextStep: Steps[step]
        })
    }

    setEnv = (env) => {
        this.setState({
            env,
            //showCreds: env && env.toLowerCase() === 'prod' //this line is commented because of prd-355
            showCreds: false
        })
    }

    setOptions = (market: string, language: string, ipgln: string, assortmentCode: any) => {
        this.setState({
            market,
            language,
            ipgln,
            ipglnCodeWithDescription: getIpglnDescription(ipgln, market),
            assortmentCodeWithBrandName: assortmentCode,
            assortmentCode: assortmentCode.split("-").slice(-1)[0].trim(),
            env: "",
        })
        this.setEnv("")
        this.changeFileName("","")
    }

    setPasDownloadButton = (enable: boolean) => {
        this.setState({
            disablePasDownload: enable
        })
    }

    setAugmentButton = (enable: boolean) => {
        this.setState({
            disableAugmentButton: enable
        })
    }

    setMarket= (market: string)=>{
        this.setState({
           market 
        })
    }

    setLanguage= (language: string)=>{
        this.setState({
           language 
        })
    }

    setipgln= (ipgln: string)=>{
        this.setState({
           ipgln 
        })
    }

    setAssortment= (assortment)=>{
        this.setState({
            assortmentCode: assortment.split("-")[1].trim(),
            assortmentCodeWithBrandName:assortment 
        })
    }


    changeFileName = (name: string, gcs_key: string) => {
        this.setState({
            fileName: name,
            gcs_key: gcs_key,
            bucket_key: gcs_key
        })
    }
    render() {
        return (
            <div>
                <div className={"body_div margin_top_p"}>
                    <p style={{"color":"red", fontSize:"16px"}}><em><span style={{fontWeight:"bold",textDecoration:"underline"}}>PLEASE NOTE:</span> Due to a new implementation, PAS files downloaded before 11<sup>th</sup> October might give a validation error while uploading. Request you to download a recent PAS and make the required changes in it.</em></p>
                </div>
                <div className={"body_div margin_top_p"}>
                    <p>Welcome to the Product Augmentation Portal (PAP) where product data from our systems of record SAP/Kwery/PLM can be augmented before it is shown on Unilever’s digital consumer web experiences (e.g. brand websites on the AEM SaaS platform).</p>
                </div>
                <div className={"body_div margin_mid_p"}>
                    <p>This portal is backward compatible to ingest PIM/PDX sheets but brands & markets are encouraged to use the new simpler Product Augmentation Sheet (PAS) available to download from this portal.</p>
                </div>
                {/* <div className={"body_div margin_low_p"}>
                    <p>Markets not onboarded to the eContent Suite (PIM Lite) can continue to augment product data using the existing PIM Sheet.</p>
                </div> */}
                <DashedLine />
                <div>
                    <Step1 disabled={this.state.nextStep.includes("Step1") ? false : true} moveNextStep={this.moveNextStep} step={this.state.nextStep} setOptions={this.setOptions} />
                </div>
                <DashedLine />
                <div>
                    <Step2 fileName={this.state.fileName} disabled={this.state.nextStep.includes("Step2") ? false : true} moveNextStep={this.moveNextStep} market={this.state.market} language={this.state.language} ipgln={this.state.ipgln} assortmentCode={this.state.assortmentCode} setEnv={this.setEnv} env={this.state.env}/>
                </div>
                <DashedLine />
                {/* <div>
                <Step3  fileName={this.state.fileName} disabled={this.state.nextStep.includes("Step3")? false: true} moveNextStep={this.moveNextStep} market={this.state.market} language={this.state.language} ipgln={this.state.ipgln} assortmentCode={this.state.assortmentCode}/>
            </div>
            <DashedLine /> */}
                <div>
                    <Step3 fileName={this.state.fileName} disabled={this.state.nextStep.includes("Step3") ? false : true} moveNextStep={this.moveNextStep} market={this.state.market} language={this.state.language} ipgln={this.state.ipgln} assortmentCode={this.state.assortmentCode} env={this.state.env} setPasDownloadButton={this.setPasDownloadButton}/>
                </div>
                <DashedLine />
                <div>
                    <Step4 changeFileName={this.changeFileName} fileName={this.state.fileName} disabled={this.state.nextStep.includes("Step4") ? false : true} moveNextStep={this.moveNextStep} market={this.state.market} language={this.state.language} ipgln={this.state.ipgln} assortmentCode={this.state.assortmentCode} env={this.state.env} setNonModifiableGtins={this.setNonModifiableGtins}/>
                </div>
                <DashedLine />
                <div>
                    <Step5 fileName={this.state.fileName} disabled={this.state.nextStep.includes("Step5") ? false : true} moveNextStep={this.moveNextStep} market={this.state.market} language={this.state.language} ipgln={this.state.ipgln} assortmentCode={this.state.assortmentCode} env={this.state.env} gcs_key={this.state.gcs_key} bucket_key={this.state.bucket_key} showCreds={this.state.showCreds} ipglnCodeWithDescription = {this.state.ipglnCodeWithDescription} assortmentCodeWithBrandName = {this.state.assortmentCodeWithBrandName} nonModifiableGtins = {this.state.nonModifiableGtins}/>
                </div>
            </div>
        )
    }
}

export default Body