import {FunctionComponent} from "react";
import logo from '../unilever.svg';
import './components.css'

export const Banner: FunctionComponent<{}> = () => <div className={"banner_main"}>
    <div className={"unilever_logo_div"}>
    <img src={logo} alt="Unilever" className={"unilever_logo"}/>
    </div>
    <div className={"header_div"}>
    <h1 className={"header_text"} ><span>Product Augmentation Portal</span></h1>
    </div>
</div>