import React from "react";
import {Banner} from "../components/Banner"
import Body from '../components/Body'


class Main extends React.Component<{}, {}> {
    render(){
        return(
             <div>
                 <Banner />
                 <Body />
             </div>
        )
    }

}

export default Main