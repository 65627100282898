import React from 'react'

type NewState = {
    selectedValue: string
}
type NewProps = {
    fileName: string,
    disabled: boolean,
    moveNextStep: any,
    market: string,
    language: string,
    ipgln: string,
    assortmentCode: string,
    setEnv: Function,
    env: string
}

class Step2 extends React.Component<NewProps, NewState>{
    constructor(props) {
        super(props)
        this.state = {
            selectedValue: ''
        }
    }


    setEnv = (e) => {
        //console.log(e.target.value);
        this.setState({
            selectedValue: e.target.value
        })
        this.props.setEnv(e.target.value)
        this.props.moveNextStep('Step2')
    }

    componentDidUpdate = () => {
        if (this.props.env === '') {
            document.getElementsByName("env").forEach(radio => {
                let radioButton = radio as HTMLInputElement
                radioButton.checked = false
            })
        }
    }

    reset = (e) => {
        this.setState({
            selectedValue: ''
        })

    }

    render() {
        // const { selectedValue } = this.state;
        console.log(this.props.disabled);
        return <div className={"body_div margin_step_p"}>
            <div>
                <div className={"step2"}>
                    <div><b>Step 2&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;&nbsp;</b></div>
                    <div>Please select the environment you want to update.</div>
                </div>
                <div className={"checkbox_div"}>
                    <div className={"checkbox_main checkbox_left"}>
                        <label>
                        <input onClick={(e) => this.setEnv(e)} name='env' disabled={this.props.disabled} type="radio" value="prod" className={"checkbox"} required /></label>
                        <div className={"radio_label"}>Production / UAT</div>
                    </div>
                    <div className={"checkbox_main checkbox_right"}>
                    <label>
                        <input onClick={(e) => this.setEnv(e)} name='env' disabled={this.props.disabled} type="radio" value="dev" className={"checkbox"} required />
                        </label>
                        <div className={"radio_label"}>QA / Dev</div>
                    </div>

                </div>

            </div>
            {/* <div className={"creds"}>
                <div className="creds-field username">
                    <div className="credlabel">
                        <div><b>ID&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;&nbsp;</b></div>
                    </div>
                    <div className="cred-input">
                        <input type="text" name="username" id="usename" />
                    </div>

                </div>
                <div className="creds-field password">
                    <div className="credlabel">
                        <div><b>Secret&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;&nbsp;</b></div>
                    </div>
                    <div className="cred-input">
                        <input type="password" name="password" id="password" />
                    </div>
                </div>
            </div> */}

        </div>
    }
}

export default Step2;

