
import React from 'react'
import Modal from 'react-modal';

type NewState = {
    emails: string,
    client_id: string,
    client_secret: string,
    open_popup: boolean,
    error_msg: string, 
    loader: string,
    timer: string,
    timecounter: number
    disableAugmentButton: boolean
}
type NewProps = {
    fileName: string,
    disabled: boolean,
    moveNextStep: any,
    market: string,
    language: string,
    ipgln: string,
    assortmentCode: string,
    env: string,
    gcs_key: string,
    bucket_key: string,
    showCreds: boolean,
    ipglnCodeWithDescription: string,
    assortmentCodeWithBrandName: string,
    nonModifiableGtins: []
}
type response = {
    message: string
}

const customStyles_Gen_Error = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '18%',
        height: 'auto'
    },
};

const customStyles_Pass = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '15%',
        height: 'auto'
    },
};

const {
    // REACT_APP_EXTERNAL_DEV_API,
    // REACT_APP_EXTERNAL_DEV_API_KEY,
    // REACT_APP_EXTERNAL_PROD_API,
    // REACT_APP_EXTERNAL_PROD_API_KEY,
    // REACT_APP_GCP_PORTAL_BASE_URL_PROD,
    // REACT_APP_GCP_PORTAL_BASE_URL_DEV
    REACT_APP_GCP_API_GATEWAY_BASEURL_PROD,
    REACT_APP_GCP_API_GATEWAY_BASEURL_DEV,
    REACT_APP_GCP_API_GATEWAY_API_KEY_PROD,
    REACT_APP_GCP_API_GATEWAY_API_KEY_DEV,
} = process.env


class Step5 extends React.Component<NewProps, NewState>{
    constructor(props) {
        super(props)
        this.state = {
            emails: '',
            client_id: '',
            client_secret: '',
            open_popup: false,
            error_msg: '',
            loader: "none",
            timer: "none",
            timecounter: 60,
            disableAugmentButton: false
        }
    }

    closeModal = () => {
        this.setState({
            open_popup: false
        })
    }

    handleChange(event: any) {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        })
    }

    handleBlur(event: any) {
        console.log('You finished typing:', this.state[event.target.name])
        console.log(this.state);

    }

    validateEmailString(emails) {
        const regex = new RegExp(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/gm);
        return regex.test(emails.replace(/\s/g, ''))
    }

    triggerTask = async () => {
       

        let data = {
            countryCode: this.props.market,
            languageCode: this.props.language,
            ipglnCode: this.props.ipgln,
            brandCode: this.props.assortmentCode,
            env: this.props.env,
            gcs_key: this.props.gcs_key,
            bucket_key: this.props.bucket_key,
            toAddresses: this.state.emails,
            client_id: this.state.client_id,
            client_secret: this.state.client_secret,
            ipglnCodeWithDescription: this.props.ipglnCodeWithDescription,
            assortmentCodeWithBrandName: this.props.assortmentCodeWithBrandName,
            localFileName: this.props.fileName,
            non_modifiable_gtins: this.props.nonModifiableGtins
        }
        let error = ""
        if (this.state.emails.trim() === "") error = "'Email'"
        // below is commented because of prd-355
        //if (this.props.env === 'prod' && this.state.client_id.trim() === "") error = (error !== "" ? error + ", " : "") + " 'ID'"
        //if (this.props.env === 'prod' && this.state.client_secret.trim() === "") error = (error !== "" ? error + " and " : "") + "'Secret'"
        if (error.trim() !== "") {
            error = error + " can't be empty."
            this.setState({
                open_popup: true,
                error_msg: error,
                loader: "none"
            })
        } else {
            this.setState({
                disableAugmentButton : true,
                loader: "block",
                //open_popup: true, 
                timecounter: this.state.timecounter,
                error_msg: ""
            })
            const REACT_APP_EXTERNAL_API = this.props.env === "prod" ? REACT_APP_GCP_API_GATEWAY_BASEURL_PROD : REACT_APP_GCP_API_GATEWAY_BASEURL_DEV
            const REACT_APP_EXTERNAL_API_KEY = this.props.env === "prod" ? REACT_APP_GCP_API_GATEWAY_API_KEY_PROD : REACT_APP_GCP_API_GATEWAY_API_KEY_DEV

            const response = await fetch(`${REACT_APP_EXTERNAL_API}/augment`, {
                method: 'POST', body: JSON.stringify(data)!, headers: {
                    'Content-Type': "application/json",
                    "x-api-key" : REACT_APP_EXTERNAL_API_KEY!
                }
            });
            const res: response = await response.json();
            console.log("printing raw response")
            console.log(res);
            console.log("printing message from response");
            console.log(res.message);
            if (response.ok) {
                // this.setState({
                //     loader: "block",
                //     //open_popup: true, 
                //     timecounter: this.state.timecounter,
                //     error_msg: ""
                // })
                //this.props.moveNextStep('Step5')
            }else{
                this.setState({
                    loader: "none",
                    open_popup: true,
                    error_msg: res["error"],
                    disableAugmentButton: false
                })
            }

        }
    }

    componentDidMount(){
        console.log("step5 componentDidMount called()");
        
        // const interval = setInterval(() => {
        //     const { timecounter } = this.state
        //     if (timecounter > 0 && this.state.loader === 'block') {
        //         this.setState(({ timecounter }) => ({
        //             timecounter: timecounter - 1
        //         }))
        //     }
        //     if (timecounter === 0) {
        //             clearInterval(interval)
        //             this.setState(({ timecounter }) => ({
        //                 timecounter: 60,
        //                 loader: 'none',
        //                 open_popup: true
        //             }))
        //             this.props.moveNextStep('Step5')
        //     } 
        // },1000)
    }

    componentDidUpdate() {
        const { timecounter } = this.state
        if (timecounter === 60 && this.state.loader === 'block'){
            const interval = setInterval(() => {
                // console.log(`Step 5 componentdidupdate timecounter is ticking`)
                const { timecounter } = this.state
                if (timecounter > 0 && this.state.loader === 'block') {
                    // console.log(`Step 5 componentdidupdate with time counter ${this.state.timecounter}`)
                    this.setState(({ timecounter }) => ({
                        timecounter: timecounter - 1
                    }))
                }
                if (timecounter === 0) {
                        // console.log(`Step 5 componentdidupdate time counter is 0`)
                        clearInterval(interval)
                        this.setState(({ timecounter }) => ({
                            timecounter: 60,
                            loader: 'none',
                            open_popup: true,
                            disableAugmentButton: false
                        }))
                        this.props.moveNextStep('Step5')
                } 
            },1000)
        }
    }

    render() {
        return <div className={"body_div margin_step_p"} >
            <div >
                <div className={"step_header_text"} style={{ marginBottom: "10px" }}><div style={{ 'width': '9em' }}><b>Step 5&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;</b></div> <div>Please provide comma separated email(s) to be notified of progress and click the Augment Data button. This usually takes less than 1 minute and when an email is received the changes will appear on websites when Akamai caches are cleared or expire.</div></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ 'width': '100%' }}>
                    <div className={"email_section"} style={{ 'width': '90%' }}>
                        <div >Email&nbsp;<span style={{ color: 'red' }}>*</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div style={{ 'width': '100%' }}>
                            <textarea style={{fontSize: "16px", fontFamily: "proxima-n-w01-reg, sans-serif"}} aria-label="Enter comma separated emails" name="emails" className="email_box" placeholder="Enter comma separated email(s)" onChange={this.handleChange.bind(this)} value={this.state.emails} onBlur={this.handleBlur.bind(this)}>
                            </textarea>
                        </div>
                    </div>
                    <div className={"creds"} style={{ display: (this.props.showCreds ? 'block' : 'none') }}>
                        <div className="creds-field username">
                            <div className="credlabel">
                                <div>ID&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;&nbsp;</div>
                            </div>
                            <div className="cred-input">
                                <input aria-label="Enter client id" type="text" name="client_id" id="client_id" onChange={this.handleChange.bind(this)} value={this.state.client_id} onBlur={this.handleBlur.bind(this)} />
                            </div>

                        </div>
                        <div className="creds-field password">
                            <div className="credlabel">
                                <div>Secret&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;&nbsp;</div>
                            </div>
                            <div className="cred-input">
                                <input aria-label="Enter client secret" type="password" name="client_secret" id="client_secret" onChange={this.handleChange.bind(this)} value={this.state.client_secret} onBlur={this.handleBlur.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"step_wrapper btn-augment"}>
                <div className="timer step5_timer_position" style={{ display: this.state.loader  }}>{this.state.timecounter}</div>    
                <div className="loader step5_loader_position" style={{ display: this.state.loader  }}></div>
                    <button aria-label="click Augment data" className="button_ui" style={{ width: "13em" }} onClick={this.triggerTask} disabled={this.props.disabled || this.state.disableAugmentButton}>
                        Augment Data
                    </button>
                </div>

            </div>

            <div className="final_status">
                {
                    this.state.error_msg !== "" ? (
                        <Modal
                            isOpen={this.state.open_popup}
                            onRequestClose={this.closeModal}
                            contentLabel="Augment Request Failed"
                            style={customStyles_Gen_Error}
                        >
                            <h2>Augment Request Failed</h2>
                            <span style={{ color: "red", display: "block", marginBottom: "30px" }}>{this.state.error_msg}</span>
                            <button aria-label="close" className="button_ui" onClick={this.closeModal}>Close</button>
                            &nbsp; &nbsp;

                        </Modal>

                    ) : (
                        <Modal
                            isOpen={this.state.open_popup}
                            onRequestClose={this.closeModal}
                            contentLabel="Augment Request Submitted"
                            style={customStyles_Pass}
                        >
                            <div style={{ marginLeft: "13%",marginRight: "13%" }}>
                                <br />
                                <p style={{ color: "green" }} >Augmentation request successfully processed.</p>
                                <br />
                                <button aria-label="close" className="button_ui" style={{margin: "inherit"}} onClick={this.closeModal}>Close</button>
                            </div>
                        </Modal>
                    )


                }

            </div>

        </div>
    }
}

export default Step5