import React from 'react'
import fetch from 'node-fetch'
import Modal from 'react-modal';

type error = {
}[] | string

type NewState = {
    fileUploadState: string,
    open: boolean,
    error: any,
    validationStatus: boolean,
    generic_error_msg: string,
    presignedurl: string,
    loader: string,
    timecounter: number
    nonModifiableGtins: []
}
type NewProps = {
    changeFileName: any,
    fileName: string,
    disabled: boolean,
    moveNextStep: any
    market: string,
    language: string,
    ipgln: string,
    assortmentCode: string,
    env: string,
    setNonModifiableGtins: Function
}

type response = {
    message: string,
}

type presignedUrlResponse = {
    message: string,
    presigned_upload_url: string,
    gcs_key: string
}

type response2 = {
    s3_key: string,
    message: string,
    export_url: string,
    object_exists: string,
    validation_json_data?: string[]
}

const {
    // REACT_APP_EXTERNAL_DEV_API,
    // REACT_APP_EXTERNAL_DEV_API_KEY,
    // REACT_APP_EXTERNAL_PROD_API,
    // REACT_APP_EXTERNAL_PROD_API_KEY,
    // REACT_APP_GCP_PORTAL_BASE_URL_PROD,
    // REACT_APP_GCP_PORTAL_BASE_URL_DEV,
    // REACT_APP_GCP_SIGNED_URL_UPLOAD_PROD,
    // REACT_APP_GCP_SIGNED_URL_UPLOAD_DEV,
    // REACT_APP_GCP_PAS_VALIDATE_PROD,
    // REACT_APP_GCP_PAS_VALIDATE_DEV
    REACT_APP_GCP_API_GATEWAY_BASEURL_PROD,
    REACT_APP_GCP_API_GATEWAY_BASEURL_DEV,
    REACT_APP_GCP_API_GATEWAY_API_KEY_PROD,
    REACT_APP_GCP_API_GATEWAY_API_KEY_DEV,
} = process.env

console.log(process.env);


const customStyles_Error = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        overflow: 'scroll',
        height: 'auto',
        "maxHeight": '60%'
    },
};
const customStyles_Gen_Error = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        height: 'auto'
    },
};

const customStyles_Pass = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '15%',
        height: '25%'
    },
};

const customStyles_Pass_NonModifiableGTINS = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        overflow: 'scroll',
        height: 'auto',
        "maxHeight": '60%'
    },
};

class Step4 extends React.Component<NewProps, NewState>{
    inputReference: any;
    constructor(props: NewProps | Readonly<NewProps>) {
        super(props);
        this.state = {
            fileUploadState: "No File Selected",
            open: false,
            error: "",
            validationStatus: true,
            generic_error_msg: "",
            presignedurl: "",
            loader: "none",
            timecounter: 0,
            nonModifiableGtins: []
        }
        this.inputReference = React.createRef();
    }
    closeModal = () => {
        this.setState({
            open: false
        })
    }

    downloadPas = () => {
        const link = document.createElement('a');
        link.href = this.state.presignedurl;
        link.setAttribute('download', `testfile.xlsx`);
        document.body.appendChild(link);
        link.click();

    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    createUploadData = (file) => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsArrayBuffer(file);
            fr.onload = function () {
                resolve(fr.result);
                console.log(fr.result);
            }
            fr.onerror = (error) => {
                reject(error);
            }
        })
    } 
    componentDidUpdate(prevProps, prevState) {
        const tick = setInterval(() => {
            if (prevState.timecounter !== this.state.timecounter && this.state.loader === 'block') {
                this.setState(() => ({
                    timecounter: this.state.timecounter + 1
                }))
            }
            clearInterval(tick)
            // if (this.state.loader ===  'none') {
            //         clearInterval(tick)
            //         this.setState({
            //             timecounter: 0,
            //         })
            // } 
        }, 1000)
    }
    timer = ms => new Promise(res => setTimeout(res, ms));

    poll = async (url: string, param: object) => {
        let object_exists = "no";
        let response: response2;
        let res;
        while (object_exists === 'no') {
            await this.timer(5000);
            try {
                res = await fetch(url, param);
                response = await res.json();
                console.log(response);
                object_exists = response['object_exists'];
            } catch (e) { /* keep looping */ }
        }
        res = await fetch(url, param);
        response = res.json();
        return response;
    }

    fileUploadInputChange = async (e) => {
        try {
            const REACT_APP_EXTERNAL_API = this.props.env === "prod" ? REACT_APP_GCP_API_GATEWAY_BASEURL_PROD : REACT_APP_GCP_API_GATEWAY_BASEURL_DEV
            const REACT_APP_EXTERNAL_API_KEY = this.props.env === "prod" ? REACT_APP_GCP_API_GATEWAY_API_KEY_PROD : REACT_APP_GCP_API_GATEWAY_API_KEY_DEV

            // const SIGNED_URL_UPLOAD = this.props.env === "prod" ? REACT_APP_GCP_SIGNED_URL_UPLOAD_PROD : REACT_APP_GCP_SIGNED_URL_UPLOAD_DEV
            // const REACT_APP_GCP_PAS_VALIDATE_ENDPOINT = this.props.env === "prod" ? REACT_APP_GCP_PAS_VALIDATE_PROD : REACT_APP_GCP_PAS_VALIDATE_DEV

            let name = e.target.files[0].name
            let fileNameArray = name.split(".")!;

            let extension = fileNameArray.pop()!;
            let new_name = `${this.props.market.toLowerCase()}_${this.props.language.toLowerCase()}_${this.props.assortmentCode.toUpperCase()}_${this.props.ipgln}`
            let fullFileName = `${new_name}_${Date.now()}.${extension}`!;
            console.log("fullFileName is " + fullFileName);
            
            this.setState({
                fileUploadState: "Uploading and validating file… please wait.",
                loader: "block",
                timecounter: this.state.timecounter + 1
            })
            let data: any = await this.convertBase64(e.target.files[0])
            let uploadData: any = await this.createUploadData(e.target.files[0])
            
            // console.log(`${SIGNED_URL_UPLOAD}/${fullFileName}`);
            

            //fetching presigned url for uploading the file with apigateway.   
            const presignedUploadUrlResponse = await fetch(`${REACT_APP_EXTERNAL_API}/upload/signedurl/${fullFileName}`, 
                                                        {
                                                            method: 'GET', headers: {
                                                                "Content-Type": "application/octet-stream",
                                                                "x-api-key" : REACT_APP_EXTERNAL_API_KEY!
                                                            }
                                                        });
            //fetching presigned url for uploading the file.   
            // const presignedUploadUrlResponse = await fetch(`${REACT_APP_EXTERNAL_API}/prd-api-v2-service-uploadpas-signedurl-${this.props.env}/${fullFileName}`, 
            //                                             {
            //                                                 method: 'GET', headers: {
            //                                                     "Content-Type": "application/octet-stream",
            //                                                 }
            //                                             });

            // const presignedUploadUrlResponse = await fetch(`${SIGNED_URL_UPLOAD}/${fullFileName}`, // cloud function gen 2
            //                                             {
            //                                                 method: 'GET', headers: {
            //                                                     "Content-Type": "application/octet-stream",
            //                                                 }
            //                                             });
            console.log(presignedUploadUrlResponse);  
            // fetching presigned upload url from the lambda response    
            const presignedUrlRes: presignedUrlResponse = await presignedUploadUrlResponse.json();
            console.log(presignedUrlRes)  

            // // uploading file to GCS via presigned upload url
            const uploadResponse = await fetch(presignedUrlRes.presigned_upload_url, 
                {
                    method: 'PUT', 
                    body: uploadData,  
                    headers: {
                        "Content-Type": "application/octet-stream",
                        "Content-Length": uploadData.length,
                        "Access-Control-Allow-Origin": "*",
                        'Access-Control-Allow-Headers': '*',
                        'upload': "true"     // this parameter is mandatory to be passed for PUT request to upload file.
                    }
                }
            );

            console.log(uploadResponse)
            
            const response = await fetch(`${REACT_APP_EXTERNAL_API}/pas/validate/${fullFileName}`, {
                method: 'POST', 
                // body: data!, 
                headers: {
                    'Content-Type': "application/json",
                    'x-api-key': REACT_APP_EXTERNAL_API_KEY!
                }
            });
            e.target.value = ''
            const res: response = await response.json();
            console.log("printing raw response")
            console.log(res);
            console.log("printing message from response");
            console.log(res.message);

            if (response.ok) {
                if (res["status"] === "pass") {
                    this.props.changeFileName(name, res["gcs_key"]) // setting filename and s3Key in the Base component, so that it can be sent forward to next step
                    this.setState({
                        open: true,
                        error: "Validation passed.",
                        validationStatus: true,
                        presignedurl: res["pre_signed_url"],
                        fileUploadState: "Successfully uploaded.",
                        loader: "none",
                        timecounter: 0,
                        nonModifiableGtins: res["non_modifiable_gtins"] || []
                    })
                    this.props.setNonModifiableGtins(res["non_modifiable_gtins"] || [])
                    this.props.moveNextStep('Step4')
                } else {
                    let tempErr = res["errors"]
                    tempErr.sort((a: any, b: any) => a.row - b.row);
                    this.setState({
                        open: true,
                        error: tempErr,
                        validationStatus: false,
                        generic_error_msg: res["generic_message"] || "",
                        presignedurl: res["pre_signed_url"],
                        fileUploadState: "Upload failed due to validation errors.",
                        loader: "none",
                        timecounter: 0,
                        nonModifiableGtins:[]
                    })
                }
            } else {
                this.setState({
                    open: true,
                    error: res.message["errors"],
                    loader: "none",
                    timecounter: 0
                })
            }

            return res
        } catch (e) {
            console.log(e)
        }

    }

    fileUploadAction = () => this.inputReference.current.click();


    render() {
        return <div className={"body_div margin_step_p"}>
            <div>

                <div className={"step_header_text"}><div><b>Step 4&nbsp;<span style={{ color: 'red' }}>*</span> &nbsp;</b></div> <div>Please upload the PIM or PAS with your augmented product data.</div></div>
                <div className="timer step4_timer_position" style={{ display: this.state.loader }}>{this.state.timecounter}</div>
                <div className="loader step4_loader_position" style={{ display: this.state.loader }}></div>
                <button aria-label="Click upload file button" className="button_ui" style={{ width: '18%', float: 'right' }} onClick={this.fileUploadAction} disabled={this.props.disabled}>
                    Upload File
                </button>
            </div>
            <div className={"step_wrapper"}>
                <p aria-label="upload status" className={"file_text"} style={{ marginLeft: ".5%", marginBottom: "0", marginTop: "0" }}>
                    {this.props.fileName.length > 0 ? this.props.fileName : this.state.fileUploadState}
                </p>
                <input type="file" hidden ref={this.inputReference} onChange={this.fileUploadInputChange} accept=".xls, .xlsx, .xlsm" />


            </div>
            <div >
                {
                    this.state.validationStatus === true ? (
                        (this.state.nonModifiableGtins.length === 0) ? (
                            <Modal
                                isOpen={this.state.open}
                                onRequestClose={this.closeModal}
                                contentLabel="Validation Success"
                                style={customStyles_Pass}
                                appElement={document.getElementById('root') || undefined}
                            >
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100%"}}>
                                    <br />
                                    <p style={{ color: "green" }} >Validation has passed, please complete Step 5 by entering emails to be notified of changes and clicking Augment Data.</p>
                                    <br />
                                    <button aria-label="close validation dialog" className="button_ui" onClick={this.closeModal}>Close</button>
                                </div>
                            </Modal>
                        ) : (
                            <Modal
                                isOpen={this.state.open}
                                onRequestClose={this.closeModal}
                                contentLabel="Validation Success"
                                style={customStyles_Pass_NonModifiableGTINS}
                                appElement={document.getElementById('root') || undefined}
                            >
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100%"}}>
                                    <p style={{ color: "green", fontWeight: "bold" }} >Validation has passed, please complete Step 5 by entering emails to be notified of changes and clicking Augment Data.</p>
                                    <p style={{ color: "green", marginBottom: "0"}} >Below GTINs will not be augmented because you are trying to modify an earlier version of their data. 
                                        Please refer the doccumentation <a target='_blank' rel="noreferrer" href='https://ce-platform.atlassian.net/wiki/spaces/PRD/pages/7893387522/3.06+Understanding+Errors+Warnings+Solutions#3.06.39---Below-GTINs-will-not-be-augmented-because-you-are-trying-to-modify-an-earlier-version-of-their-data.'>here</a>.</p>
                                    <p style={{ color: "green" }} >{this.state.nonModifiableGtins.join(", ")}</p>
                                    <button aria-label="close validation dialog" className="button_ui" onClick={this.closeModal}>Close</button>
                                </div>
                            </Modal>
                        )
                    ) : (
                        (this.state.error.length) === 0 && this.state.generic_error_msg !== "" ? (
                            <Modal
                                isOpen={this.state.open}
                                onRequestClose={this.closeModal}
                                contentLabel="Validation Errors"
                                style={customStyles_Gen_Error}
                                appElement={document.getElementById('root') || undefined}
                            >
                                <h2>Validation Results</h2>
                                <span style={{ color: "red", display: "block", marginBottom: "30px" }}>{this.state.generic_error_msg}</span>
                                <button aria-label="close validation dialog" className="button_ui" onClick={this.closeModal}>Close</button>
                                &nbsp; &nbsp;
                                <button aria-label="download PAS with error" className="button_ui" onClick={this.downloadPas}>Download</button>

                            </Modal>

                        ) : (
                            <Modal
                                isOpen={this.state.open}
                                onRequestClose={this.closeModal}
                                contentLabel="Validation Errors"
                                style={customStyles_Error}
                                appElement={document.getElementById('root') || undefined}
                            >
                                <h2>Validation Results</h2>
                                {this.state.error.length === 1 ? (
                                    <p> {this.state.error.length} error has been found. It is shown below, please click the Download button and address the cell with a red background.</p>
                                ) : (
                                    <p> {this.state.error.length} errors have been found. These are shown below, please click the Download button and address the cells with a red background.</p>
                                )}
                                <br />
                                <hr />
                                <br />
                                {
                                    <table cellSpacing="10" width="100%" style={{ textAlign: "left" }}>
                                        <thead>
                                            <tr>
                                                <th>Row No.</th>
                                                <th>Column Name</th>
                                                <th>Error</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.error.map((error) => {
                                                    return <tr key={error.row} >
                                                        <td>{error.row}</td>
                                                        <td>{error.col_header}</td>
                                                        <td>{error.error_message}</td>
                                                        <td />
                                                    </tr>
                                                })
                                            }

                                        </tbody>

                                    </table>

                                }
                                <br />
                                <hr />
                                <br />
                                <button aria-label="close validation dialog" className="button_ui" onClick={this.closeModal}>Close</button>
                                &nbsp; &nbsp;
                                <button aria-label="download PAS with error" className="button_ui" onClick={this.downloadPas}>Download</button>

                            </Modal>
                        )
                    )
                }



            </div>
        </div>
    }
}

export default Step4