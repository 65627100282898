import React from "react";

type NewState = {
  helperMessage: string;
  loader: string;
  includeRD: boolean;
  //exportRnDData: string,
  includeEcomData: boolean;
  //exportEcomData: string,
  includeShopifyData: boolean;
  //exportShopifyData: string,
  includeThirdPartyData: boolean;
  //thirdpartydata: string,
  includeLeapData: boolean;
  //leapdata: string
  includeAwardsData: boolean;
  //awardsdata: string,
  includeClaimsData: boolean;
  //claimsdata: string,
  includeCustomData: boolean;
  includeEcomWebImage: boolean
  //customdata: string,
  includeAll: boolean;
  timecounter: number;
  disablePasDownloadButton: boolean;
};
type NewProps = {
  fileName: string;
  disabled: boolean;
  moveNextStep: any;
  market: string;
  language: string;
  ipgln: string;
  assortmentCode: string;
  env: string;
  setPasDownloadButton: Function;
};

type response = {
  s3key: string;
  message: string;
  export_url: string;
  object_exists: string;
};
type response2 = {
  s3_key: string;
  message: string;
  export_url: string;
  object_exists: string;
};

type response1 = {
  countryCode: string;
  languageCode: string;
  ipglnCode: string;
  brandCode: string;
  env: string;
  bucket_excel_name: string;
  bucket_key: string;
};

const {
  REACT_APP_GCP_API_GATEWAY_BASEURL_PROD,
  REACT_APP_GCP_API_GATEWAY_BASEURL_DEV,
  REACT_APP_GCP_API_GATEWAY_API_KEY_PROD,
  REACT_APP_GCP_API_GATEWAY_API_KEY_DEV,
} = process.env;

class Step3 extends React.Component<NewProps, NewState> {
  constructor(props) {
    super(props);
    this.state = {
      helperMessage: "",
      loader: "none",
      includeRD: false,
      //exportRnDData: "false",
      includeEcomData: false,
      //exportEcomData: "false",
      includeShopifyData: false,
      //exportShopifyData: "false",
      includeThirdPartyData: false,
      //thirdpartydata: "false",
      includeLeapData: false,
      //leapdata: "false",
      includeAwardsData: false,
      //awardsdata: "false",
      includeClaimsData: false,
      //claimsdata: "false",
      includeCustomData: false,
      includeEcomWebImage: false,
      //customdata: "false",
      timecounter: 0,
      disablePasDownloadButton: false,
      includeAll: false,
    };
  }

  timer = (ms) => new Promise((res) => setTimeout(res, ms));

  poll = async (url: string, param: object) => {
    let object_exists = "no";
    let response: response2;
    let res;
    while (object_exists === "no") {
      await this.timer(5000);
      try {
        res = await fetch(url, param);
        response = await res.json();
        console.log(response);
        object_exists = response["object_exists"];
      } catch (e) {
        /* keep looping */
      }
    }
    res = await fetch(url, param);
    response = res.json();
    return response;
  };

  toggleIncludeRD = (e) => {
    this.setState({
      includeRD: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };
  toggleIncludeEcomData = (e) => {
    this.setState({
      includeEcomData: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };
  toggleIncludeShoppify = (e) => {
    this.setState({
      includeShopifyData: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };

  toggleIncludeThirdPartyData = (e) => {
    this.setState({
      includeThirdPartyData: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };

  toggleIncludeLeapData = (e) => {
    this.setState({
      includeLeapData: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };
  toggleIncludeAwardsData = (e) => {
    this.setState({
      includeAwardsData: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };
  toggleIncludeClaimsData = (e) => {
    this.setState({
      includeClaimsData: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };

  toggleIncludeCustomData = (e) => {
    this.setState({
      includeCustomData: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };

  toggleIncludeEcomWebimage = (e) => {
    this.setState({
      includeEcomWebImage: e.target.checked,
      includeAll: !e.target.checked ? false : this.state.includeAll,
    });
  };

  toggleAll = async (e) => {
    let ischecked = e.target.checked;
    this.setState({
      includeAll: ischecked,
      includeRD: ischecked,
      includeEcomData: ischecked,
      includeShopifyData: ischecked,
      includeThirdPartyData: ischecked,
      includeLeapData: ischecked,
      includeAwardsData: ischecked,
      includeClaimsData: ischecked,
      includeCustomData: ischecked,
      includeEcomWebImage: ischecked
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const tick = setInterval(() => {
      if (
        prevState.timecounter !== this.state.timecounter &&
        this.state.loader === "block"
      ) {
        this.setState(() => ({
          timecounter: this.state.timecounter + 1,
        }));
      }
      clearInterval(tick);
    }, 1000);
  }

  downloadPASAction = async () => {
    let data = {
      countryCode: this.props.market,
      languageCode: this.props.language,
      ipglnCode: this.props.ipgln,
      brandCode: this.props.assortmentCode,
      env: this.props.env,
      exportRnDData: this.state.includeRD.toString(),
      ecommdata: this.state.includeEcomData.toString(),
      exportShopifyData: this.state.includeShopifyData.toString(),
      thirdPartyData: this.state.includeThirdPartyData.toString(),
      leapData: this.state.includeLeapData.toString(),
      awardsData: this.state.includeAwardsData.toString(),
      claimsData: this.state.includeClaimsData.toString(),
      customData: this.state.includeCustomData.toString(),
      includeEcomWebImage: this.state.includeEcomWebImage.toString()
    };
    const REACT_APP_EXTERNAL_API =
      this.props.env === "prod"
        ? REACT_APP_GCP_API_GATEWAY_BASEURL_PROD
        : REACT_APP_GCP_API_GATEWAY_BASEURL_DEV;
    const REACT_APP_EXTERNAL_API_KEY =
      this.props.env === "prod"
        ? REACT_APP_GCP_API_GATEWAY_API_KEY_PROD
        : REACT_APP_GCP_API_GATEWAY_API_KEY_DEV;

    // const REACT_APP_DOWNLOAD_ENDPOINT = this.props.env === "prod" ? REACT_APP_GCP_PAS_DOWNLOAD_URL_PROD : REACT_APP_GCP_PAS_DOWNLOAD_URL_DEV
    this.setState({
      helperMessage:
        "Downloading PAS file. Please wait…this usually takes less than 1 minute.",
      loader: "block",
      timecounter: this.state.timecounter + 1,
      disablePasDownloadButton: true,
    });
    //if (this.props.env !== "prod") {

    const response = await fetch(`${REACT_APP_EXTERNAL_API}/create/pas`, {
      method: "POST",
      body: JSON.stringify(data)!,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": REACT_APP_EXTERNAL_API_KEY!,
      },
    });
    const res: response1 = await response.json();
    if (response.status === 200) {
      console.log(res);
      let bucket_key = res.bucket_key;
      const response2 = await this.poll(
        `${REACT_APP_EXTERNAL_API}/pas/download/ifpresent`,
        {
          method: "POST",
          body: JSON.stringify({ bucket_key })!,
          headers: {
            "Content-Type": "application/json",
            "x-api-key": REACT_APP_EXTERNAL_API_KEY!,
          },
        }
      );
      console.log(JSON.stringify(response2));

      // await response2.then(res => {return res.json()})
      if (response2.object_exists === "yes") {
        this.setState({
          helperMessage:
            "File downloaded successfully. Please check your browser or downloads folder.",
          loader: "none",
          timecounter: 0,
          disablePasDownloadButton: false,
        });
        const link = document.createElement("a");
        link.href = response2.export_url;
        link.setAttribute("download", `testfile.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        this.setState({
          helperMessage: "Failed to download PAS file",
          loader: "none",
          timecounter: 0,
          disablePasDownloadButton: false,
        });
      }
    } else {
      this.setState({
        helperMessage: "Unable to download PAS file",
        loader: "none",
        timecounter: 0,
        disablePasDownloadButton: false,
      });
    }

    this.props.moveNextStep("Step3");

    // const response = await fetch(`${REACT_APP_EXTERNAL_API}/create/pas`, {
    //   method: "POST",
    //   body: JSON.stringify(data)!,
    //   headers: {
    //     "Content-Type": "application/json",
    //     "x-api-key": REACT_APP_EXTERNAL_API_KEY!,
    //   },
    // })
    //   .then(async (response) => {
    //     const res: response = await response.json();
    //     if (response.status === 200) {
    //       this.setState({
    //         helperMessage:
    //           "File downloaded successfully. Please check your browser or downloads folder.",
    //         loader: "none",
    //         timecounter: 0,
    //         disablePasDownloadButton: false,
    //       });
    //       const link = document.createElement("a");
    //       link.href = res.export_url;
    //       link.setAttribute("download", `testfile.xlsx`);
    //       document.body.appendChild(link);
    //       link.click();
    //     } else {
    //       this.setState({
    //         helperMessage: "Unable to download PAS file",
    //         loader: "none",
    //         timecounter: 0,
    //         disablePasDownloadButton: false,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     this.setState({
    //       helperMessage: "Unable to download PAS file",
    //       loader: "none",
    //       timecounter: 0,
    //       disablePasDownloadButton: false,
    //     });
    //   })
    //   .finally(() => {
    //     this.props.moveNextStep("Step3");
    //   });

    // const response = await fetch(`${REACT_APP_EXTERNAL_API}/prd-api-v2-service-download-pas-${this.props.env}`, {
    //     method: 'POST', body: JSON.stringify(data)!, headers: {
    //         'Content-Type': "application/json"
    //     }
    // });
    // const response = await fetch(`${REACT_APP_DOWNLOAD_ENDPOINT}`, {
    //     method: 'POST', body: JSON.stringify(data)!, headers: {
    //         'Content-Type': "application/json"
    //     }
    // });
  };

  render() {
    //console.log(this.props.disabled);
    console.log(this.state);

    return (
      <div className={"body_div margin_step_p"}>
        <div>
          <div className={"step_header_text"}>
            <div style={{ width: "5.5em" }}>
              <b>
                Step 3<span style={{ color: "white" }}>*</span>&nbsp; &nbsp;
              </b>
            </div>{" "}
            <div>
              Download the PAS excel file. It is recommended to download the
              latest PAS before making changes to ensure you are working off the
              latest product data.
            </div>
          </div>
        </div>
        <div>
          <div className={"step_wrapper step3"}>
            <div
              className={"step_header_text step_chkbox_container "}
              style={{ marginBottom: "0px" }}
            >
              <div title="Include Ecommerce product data (i.e., all products active in KWERY & PIM that are not on the website)">
                <input
                  className={"checkboxOption"}
                  type="checkbox"
                  name="ecomdata"
                  id="ecomdata"
                  onChange={(e) => this.toggleIncludeEcomData(e)}
                  checked={this.state.includeEcomData}
                  disabled={this.props.disabled}
                />
                <label>Include Ecommerce Product Data</label>
              </div>

              <div title="Include R&D data (i.e., ingredients, allergens & nutritional information)">
                <input
                  className={"checkboxOption"}
                  type="checkbox"
                  name="rnddata"
                  id="rnddata"
                  onChange={(e) => this.toggleIncludeRD(e)}
                  checked={this.state.includeRD}
                  disabled={this.props.disabled}
                />
                <label>Include R&D Data</label>
              </div>
              <div title="Include 3rd Party Integration data (i.e., Smart Product Id, Store Locator Id, AEM Template etc)">
                <input
                  className={"checkboxOption"}
                  type="checkbox"
                  name="thirdpartydata"
                  id="thirdpartydata"
                  onChange={(e) => this.toggleIncludeThirdPartyData(e)}
                  checked={this.state.includeThirdPartyData}
                  disabled={this.props.disabled}
                />
                <label>Include 3rd Party Integration Data</label>
              </div>
              <div title="Include LEAP Sustainability data (i.e., Leap Score, PETA accreditation, plastic % etc).">
                <input
                  className={"checkboxOption"}
                  type="checkbox"
                  name="leapdata"
                  id="leapdata"
                  onChange={(e) => this.toggleIncludeLeapData(e)}
                  checked={this.state.includeLeapData}
                  disabled={this.props.disabled}
                />
                <label>Include LEAP Sustainability Data</label>
              </div>
              <div title="Include Awards & Certifications data">
                <input
                  className={"checkboxOption"}
                  type="checkbox"
                  name="awardscertificationsdata"
                  id="awardscertificationsdata"
                  onChange={(e) => this.toggleIncludeAwardsData(e)}
                  checked={this.state.includeAwardsData}
                  disabled={this.props.disabled}
                />
                <label>Include Awards & Certifications Data</label>
              </div>
              <div title="Include Claims & Provenance data (i.e., usually found on the packaging)">
                <input
                  type="checkbox"
                  name="claimsprovenancedata"
                  id="claimsprovenancedata"
                  onChange={(e) => this.toggleIncludeClaimsData(e)}
                  checked={this.state.includeClaimsData}
                  disabled={this.props.disabled}
                />
                <label>Include Claims & Provenance Data</label>
              </div>
              <div title="Include Shopify data (i.e., prices, inventory, tax code and other Shopify fields)">
                <input
                  className={"checkboxOption"}
                  type="checkbox"
                  name="shopifydata"
                  id="shopifydata"
                  onChange={(e) => this.toggleIncludeShoppify(e)}
                  checked={this.state.includeShopifyData}
                  disabled={this.props.disabled}
                />
                <label>Include Shopify Data</label>
              </div>
              <div title="Include Custom Data (i.e., custom attributes particular to this brand.com)">
                <input
                  className={"checkboxOption"}
                  type="checkbox"
                  name="customdata"
                  id="customdata"
                  onChange={(e) => this.toggleIncludeCustomData(e)}
                  checked={this.state.includeCustomData}
                  disabled={this.props.disabled}
                />
                <label>Include Custom Data</label>
              </div>
              <div title="Include Ecommerce Web Image Filters">
                <input
                  className={"checkboxOption"}
                  type="checkbox"
                  name="customdata"
                  id="customdata"
                  onChange={(e) => this.toggleIncludeEcomWebimage(e)}
                  checked={this.state.includeEcomWebImage}
                  disabled={this.props.disabled}
                />
                <label>Include Ecommerce Web Image Filters</label>
              </div>
              <div
                className={"selectall"}
                title="Include All (All data available)"
              >
                <input
                  type="checkbox"
                  name="includeall"
                  id="includeall"
                  onChange={(e) => this.toggleAll(e)}
                  checked={this.state.includeAll}
                  disabled={this.props.disabled}
                />
                <label>Select All</label>
              </div>
            </div>

            <button
              aria-label="download PAS"
              title="Download PAS"
              className="button_ui"
              style={{ width: "20%" }}
              onClick={this.downloadPASAction}
              disabled={
                this.props.disabled ||
                this.props.market.length === 0 ||
                this.props.language.length === 0 ||
                this.props.ipgln.length === 0 ||
                this.props.assortmentCode.length === 0 ||
                this.state.disablePasDownloadButton
              }
            >
              Download PAS
            </button>
          </div>
          <div className="step_status_message">
            <div
              className="timer step3_timer_position"
              style={{ display: this.state.loader }}
            >
              {this.state.timecounter}
            </div>
            <div
              className="loader step3_loader_position"
              style={{ display: this.state.loader }}
            ></div>
            <p className={"file_text"} style={{ marginLeft: "7.5%" }}>
              {this.state.helperMessage}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Step3;
