import Main from './container/Main'
import './App.css';

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
