
import React from 'react'
import dropdown from '../assets/dropdown.json'
import country_lang from '../assets/country_lang.json'
import { getIpglnDescription } from '../utils/utils.ts'
import SelectSearch, {fuzzySearch} from 'react-select-search'

type NewState = {
    market: string,
    marketArray: string[],
    language: string,
    languageArray: string[]
    ipgln: string,
    ipglnArray: string[],
    assortmentCode: string,
    assortmentArray: string[],
    loader: string
    [key: string]: string | string[]
}
type NewProps = {
    disabled: boolean,
    moveNextStep: any,
    step: string,
    setOptions: any
}
// type response = {
//     message: any[]
// }

const {
    // REACT_APP_EXTERNAL_DEV_API,
    // REACT_APP_EXTERNAL_DEV_API_KEY,
    // REACT_APP_EXTERNAL_PROD_API,
    // REACT_APP_EXTERNAL_PROD_API_KEY,
    REACT_APP_UNILEVER_API,
    // REACT_APP_GCP_COMMON_BASE_URL,
    REACT_APP_GAPI_BASE_URL,
    REACT_APP_GAPI_CLIENT_ID,
    REACT_APP_GAPI_CLIENT_SECRET
    // REACT_APP_GCP_PORTAL_BASE_URL_DEV,
    // REACT_APP_GCP_PORTAL_BASE_URL_PROD
} = process.env

class Step1 extends React.Component<NewProps, NewState>{
    constructor(props) {
        super(props)

        this.state = {
            market: "",
            marketArray: [],
            language: "",
            languageArray: [],
            ipgln: "",
            ipglnArray: [],
            assortmentCode: "",
            assortmentArray: [],
            loader: 'none'
        }
    }

    getLanguageArray = (market: string): string[] => {
        try {
            let allLanguage: any = new Set()
            let availableMarkets = dropdown.filter(i => i.iso2CountryCode === market)
            availableMarkets.map(i => allLanguage.add(i.iso2LanguageCode))
            let suplemented_lang = [...allLanguage, ...country_lang[market]]
            return [...suplemented_lang]
        } catch (e) {
            return []
        }
    }

    getIpglnArray = (market: string, language: string): string[] => {
        try {
            let allIpgln: any = new Set()
            let availableIpgln = dropdown.filter(i => i.iso2CountryCode === market && i.iso2LanguageCode === language)
            availableIpgln.map(i => allIpgln.add(i.ipglnCode))
            return [...allIpgln]
        } catch (e) {
            return []
        }
    }
    getIpglnArrayByCountry = (market: string): string[] => {
        try {
            let allIpgln: any = new Set()
            let availableIpgln = dropdown.filter(i => i.iso2CountryCode === market)
            availableIpgln.map(i => allIpgln.add(i.ipglnCode))
            return [...allIpgln]
        } catch (e) {
            return []
        }
    }

    getCountryLanguage = (country: string) => {
        return new Promise((resolve, reject) => {
            try {
                return fetch(`${REACT_APP_GAPI_BASE_URL}/api/countries/${country}/languages`, {
                    method: 'GET', headers : {
                        'Content-Type': "application/json",
                        'client_id': `${REACT_APP_GAPI_CLIENT_ID}`,
                        'client_secret': `${REACT_APP_GAPI_CLIENT_SECRET}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);
                        let apiCountryLanguages = data.map(l=>l.languageCode)
                        let jsonlang: string[] = this.getLanguageArray(country)
                        let languageSet: any = new Set([...apiCountryLanguages, ...jsonlang])
                        this.setState({
                            languageArray: [...languageSet].sort()
                        })
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            } catch (error) {
                reject(error)
            }
        })

    }

    getCountryIpglnCodes = (country: string) => {
        return new Promise((resolve, reject) => {
            try {
                return fetch(`${REACT_APP_GAPI_BASE_URL}/api/countries/${country}/ipglncodes`, {
                    method: 'GET', headers : {
                        'Content-Type': "application/json",
                        'client_id': `${REACT_APP_GAPI_CLIENT_ID}`,
                        'client_secret': `${REACT_APP_GAPI_CLIENT_SECRET}`
                    }
                }).then(response => response.json())
                    .then(data => {
                        //console.log(data);
                        let filter = "Retail"
                        let apiCountryLanguages = data.ipglnCodes.filter(k => !filter || k.IPGLNGroup.toLowerCase() === filter.toLowerCase()).map(k => k.IPGLNCode);
                        let jsonipgln: string[] = this.getIpglnArrayByCountry(country)
                        let languageSet: any = new Set([...apiCountryLanguages, ...jsonipgln])
                        if ([...languageSet].length === 0) {
                            this.setState({
                                ipglnArray: ["RETAIL0RETAIL"]
                            })
                        } else {
                            this.setState({
                                ipglnArray: [...languageSet].sort()
                            })
                        }
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            } catch (error) {
                reject(error)
            }
        })
    }

    getAssortmentCodes = (country: string) => {
        return new Promise((resolve, reject) => {
            try {
                return fetch(`${REACT_APP_UNILEVER_API}${country.toUpperCase()}`)
                    .then(response => response.json())
                    .then(async data => {
                        console.log(data);
                        let assortmentArray = await data.responseData.Items.map(i => `${i.brandName} - ${i.brandCode}`)
                        this.setState({
                            assortmentArray: assortmentArray.sort()
                        })
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            } catch (error) {
                reject(error)
            }
        })

    }

    clearSelectBox = async (listId: string) => {
        // let list = document.getElementById(listId) as HTMLSelectElement
        // list.selectedIndex = 0
        this.setState({"language":"",
            "languageArray":[]   
        })
    }
    clearLanguageSelectBox = async () => {
        // let list = document.getElementById(listId) as HTMLSelectElement
        // list.selectedIndex = 0
        this.setState({"language":"",
            "languageArray":[]   
        })
    }
    clearIpglnSelectBox = async () => {
        // let list = document.getElementById(listId) as HTMLSelectElement
        // list.selectedIndex = 0
        this.setState({"ipgln":"",
            "ipglnArray":[]   
        })
    }
    clearAssortmentSelectBox = async () => {
        // let list = document.getElementById(listId) as HTMLSelectElement
        // list.selectedIndex = 0
        this.setState({"assortmentCode":"",
            "assortmentArray":[]   
        })
    }

    // valueSelected = async (value: string, state: string) => {
    //     if (state === 'market') {
    //         await this.valueSelected1(value, state)
    //         // let response = await fetch(`${REACT_APP_UNILEVER_API}${value.toUpperCase()}`)
    //         // let assortmentArray = (await response.json()).responseData.Items.map(i => i.brandCode)
    //         // assortmentArray = assortmentArray.sort()
    //         // this.setState({
    //         //     assortmentArray
    //         // })
    //     }
    //     this.setState({ [state]: value })
    // }

    marketSelected = async (value: any)=>{
        console.log(value);
        this.setState({ loader: "block" })
        this.props.moveNextStep("Start")
        //this.clearSelectBox("languageArray");
        //this.clearSelectBox("ipgln_list");
        //this.clearSelectBox("assortment_list");
        this.clearLanguageSelectBox()
        this.clearIpglnSelectBox()
        this.clearAssortmentSelectBox()
        await Promise.allSettled(
            [
                this.getCountryLanguage(value),
                this.getCountryIpglnCodes(value),
                this.getAssortmentCodes(value)
            ]
        ).then((results) => {
            console.log(results);
        })
            .finally(() => {
                this.setState({
                    language: "",
                    ipgln: "",
                    assortmentCode: "",
                    loader: "none"
                })
            });

        this.setState({ ['market']: value })
    }

    languageSelected = async (value: any)=>{
        console.log("in side language condition");
        //this.clearSelectBox("ipgln_list");
        //this.clearSelectBox("assortment_list");
        // this.clearIpglnSelectBox()
        // this.clearAssortmentSelectBox()
        this.setState({
            ipgln: "",
            assortmentCode: ""
        })
        this.props.moveNextStep("Start")

        this.setState({ ['language']: value })
    }
    ipglnSelected = async (value: any)=>{
        console.log("in side ipgln condition");
        this.setState({
            assortmentCode: ""
        })
        //this.clearSelectBox("assortment_list");
        // this.clearAssortmentSelectBox()
        this.props.moveNextStep("Start")


        this.setState({ ['ipgln']: value })
    }
    assortmentSelected = async (value: any)=>{
        console.log("in side assortment condition");
        this.setState({ ['assortmentCode']: value })
    }


    valueSelected = async (value: string, state: string) => {
        if (state === 'market') {
            this.setState({ loader: "block" })
            this.props.moveNextStep("Start")
            this.clearSelectBox("language_list");
            this.clearSelectBox("ipgln_list");
            this.clearSelectBox("assortment_list");
            await Promise.allSettled(
                [
                    this.getCountryLanguage(value),
                    this.getCountryIpglnCodes(value),
                    this.getAssortmentCodes(value)
                ]
            ).then((results) => {
                console.log(results);
            })
                .finally(() => {
                    this.setState({
                        language: "",
                        ipgln: "",
                        assortmentCode: "",
                        loader: "none"
                    })
                });
        }
        else if (state === 'language') {
            console.log("in side language condition");

            this.clearSelectBox("ipgln_list");
            this.clearSelectBox("assortment_list");
            this.setState({
                ipgln: "",
                assortmentCode: ""
            })
            this.props.moveNextStep("Start")
        }
        else if (state === 'ipgln') {
            console.log("in side ipgln condition");
            this.setState({
                assortmentCode: ""
            })
            this.clearSelectBox("assortment_list");
            this.props.moveNextStep("Start")
        }

        this.setState({ [state]: value })
    }

    componentDidMount = async () => { 
        let response = await fetch(`${REACT_APP_GAPI_BASE_URL}/api/countries`, {
            method: 'GET', headers : {
                'Content-Type': "application/json",
                'client_id': `${REACT_APP_GAPI_CLIENT_ID}`,
                'client_secret': `${REACT_APP_GAPI_CLIENT_SECRET}`
            }
        })
        // let response = await fetch(`${GCP_COMMON_BASE_URL}/prd-api-cmn-service-markets`, {
        //     method: 'GET', headers : {
        //         'Content-Type': "application/json"
        //     }
        // })
        let res = await response.json();
        let apiMarkets = res.map(c=>c.countryCode)
        let jsonmarkets: string[] = []
        await dropdown.map(i => jsonmarkets.push(i.iso2CountryCode))
        let allMarkets: any = new Set([...jsonmarkets, ...apiMarkets, ...Object.keys(country_lang)])
        this.setState({
            marketArray: [...allMarkets].sort()
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        console.log(`the prevstate is [ market = '${prevState.market}'] [language = '${prevState.language}'] [ipgln '${prevState.ipgln}'] [assortmentCode = '${prevState.assortmentCode}']`);
        console.log(`the current state is [ market = '${this.state.market}'] [language = '${this.state.language}'] [ipgln '${this.state.ipgln}'] [assortmentCode = '${this.state.assortmentCode}']`);
    

        if (prevState.market !== this.state.market || prevState.language !== this.state.language || prevState.assortmentCode !== this.state.assortmentCode || prevState.ipgln !== this.state.ipgln) {
            try {

                //if (this.state.market.length > 0 && this.state.language.length > 0 && this.state.ipgln.length > 0 && this.state.assortmentCode.length > 0 ) {
                if (this.state.market.length > 0 && this.state.language.length > 0 && this.state.ipgln.length > 0 && this.state.assortmentCode.length > 0 && this.props.step === "Step1") {
                    console.log("I am in Step1 comonentDidUpdate");
                    this.props.moveNextStep('Step1')
                }
                this.props.setOptions(this.state.market, this.state.language, this.state.ipgln, this.state.assortmentCode)
            } catch (e) {
                console.log(e);

                console.log("All fields not selected")
            }


        }
    }
    shouldDownloadPasBtnEnable = () => {
        let value = this.state.market.length > 0 &&
            this.state.language.length > 0 &&
            this.state.ipgln.length > 0 &&
            this.state.assortmentCode.length > 0
        console.log(value)
        return value
    }

    render() {
        // console.log(process.env);

        return <div className={"body_div margin_step_p"}>
            <div>

                <div className={"step_header_text"}><div style={{ 'width': '5.5em' }}><b>Step 1&nbsp;<span style={{ color: 'red' }}>*</span> &nbsp;</b></div> <div>Please provide the required details below. If you feel a value is missing from any list box then please raise a ticket to the Product API squad in Kana.</div></div>
            </div>
            <div className={"step_wrapper step1"}>
                <div className={"select_div market_div"}>
                    <label htmlFor={"market"} className={"label_class"}>&nbsp;Select a market &nbsp;<span style={{ color: 'red' }}>*</span></label>
                    <div id="market" style={{ paddingLeft: "5px" }}>
                        <SelectSearch options={this.state.marketArray.map(i => {
                            return {
                                value: i,
                                name:i
                            }
                        })} value={this.state.market} 
                        placeholder="Choose Market" 
                        search filterOptions={fuzzySearch}
                        onChange={this.marketSelected} />
                    </div>
                    <div className="loader step1_loader_position" style={{ display: this.state.loader }}></div>
                </div>

                <div className={"select_div language_div"}>
                    <label htmlFor={"language"} className={"label_class"}>Select a language &nbsp;<span style={{ color: 'red' }}>*</span></label>
                    <div id="language">
                        <SelectSearch options={this.state.languageArray.map(i => {
                            return {
                                value: i,
                                name:i
                            }
                        })} value={this.state.language} 
                        placeholder="Choose Language" 
                        search filterOptions={fuzzySearch}
                        onChange={this.languageSelected}/>  
                    </div>
                </div>
                <div className={"select_div ipgln_div"}>
                    <label htmlFor={"ipgnl"} className={"label_class"}>Select an IPGLN &nbsp;<span style={{ color: 'red' }}>*</span></label>
                    <div id="ipgnl">
                        <SelectSearch options={this.state.ipglnArray.map(i => {
                            return {
                                value: i,
                                name: `${getIpglnDescription(i, this.state.market)}`,
                                disabled: getIpglnDescription(i, this.state.market) === i && i !== "RETAIL0RETAIL"
                            }
                        })} value={this.state.ipgln} 
                        placeholder="Choose Ipgln" 
                        search filterOptions={fuzzySearch}
                        onChange={this.ipglnSelected} />
                    </div>
                </div>
                <div className={"select_div assortment_div"}>
                    <label htmlFor={"asssortment"} className={"label_class"}>Select the Assortment Code &nbsp;<span style={{ color: 'red' }}>*</span></label>
                    <div id="asssortment">
                        <SelectSearch options={this.state.assortmentArray.map(i => {
                            return {
                                value: i,
                                name:i
                            }
                        })} value={this.state.assortmentCode} 
                        placeholder="Choose Assortment" 
                        search filterOptions={fuzzySearch}
                        onChange={this.assortmentSelected}
                         />  
                    </div>
                </div>
            </div>


        </div>
    }
}

export default Step1;

